import React, { useState, useMemo } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  PlusIcon,
  MinusSmIcon,
} from "@heroicons/react/solid"; // Import icons
import { useDispatch } from "react-redux";
import {
  addRequirementToScorecard,
  removeRequirementFromScorecard,
} from "../../../redux/scoreVendors/scoreVendorsSlice";
import { PlusSmIcon } from "@heroicons/react/outline";

const ItemList = ({ isScorecard, items, onItemSelected }) => {
  const dispatch = useDispatch();

  // State to manage the expanded categories
  const [expandedCategories, setExpandedCategories] = useState({});

  // State to manage the visibility toggle (dropdown)
  const [visibleCategoryIndexes, setVisibleCategoryIndexes] = useState({});

  const toggleItemSelection = (id) => {
    if (isScorecard) {
      dispatch(removeRequirementFromScorecard(id));
    } else {
      dispatch(addRequirementToScorecard(id));
    }
  };

  const handleRowClick = (item, event) => {
    event.stopPropagation(); // Prevent row click action when selecting/deselecting
    onItemSelected(item);
  };

  const toggleCategory = (functionalArea) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [functionalArea]: !prev[functionalArea],
    }));
  };

  // Toggle visibility for dropdown
  const toggleVisibility = (index) => {
    setVisibleCategoryIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Sort and group items by functionalArea
  const sortedAndGroupedItems = useMemo(() => {
    const sortedItems = [...items].sort((a, b) =>
      a.functionalArea.localeCompare(b.functionalArea)
    );
    return sortedItems.reduce((acc, item) => {
      (acc[item.functionalArea] = acc[item.functionalArea] || []).push(item);
      return acc;
    }, {});
  }, [items]);

  return (
    <div className="flex flex-col">
      {Object.keys(sortedAndGroupedItems).map((functionalArea, index) => (
        <div key={functionalArea}>
          {/* Category Header */}
          <div
            className="flex items-center justify-between text-lg font-semibold w-full bg-white border-b-2 p-2 my-1 cursor-pointer"
            onClick={() => toggleVisibility(index)} // Click to toggle visibility
          >
            <span>{functionalArea}</span>
            <ChevronDownIcon
              className={`h-6 w-6 transform origin-center transition duration-200 ease-out ${
                visibleCategoryIndexes[index] && "!rotate-180"
              }`}
            />
            {/* Chevron icon for dropdown functionality */}
          </div>

          {/* Conditionally render items based on visibility, with animation */}
          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              visibleCategoryIndexes[index]
                ? `max-h-[${
                    sortedAndGroupedItems?.length
                      ? sortedAndGroupedItems.length * 200
                      : 1000
                  }px] opacity-100`
                : "max-h-0 opacity-0"
            }`}
            style={{ transitionProperty: "max-height, opacity" }} // Apply both max-height and opacity transitions
          >
            {sortedAndGroupedItems[functionalArea].map((item) => (
              <div
                key={item._id}
                className="flex p-2 items-center w-full text-sm focus:outline-none border-b"
              >
                <div className="w-full">{item.name}</div>

                <span
                  onClick={(event) => handleRowClick(item, event)}
                  className="flex justify-between items-center cursor-pointer p-1 "
                >
                  <button>
                    <div className="flex bg-white hover:bg-gray-100 text-md font-semibold rounded-md border-2 items-center justify-center">
                      <InformationCircleIcon className="w-6 h-6" />
                    </div>
                  </button>
                </span>

                <div
                  className="flex justify-end"
                  onClick={() => toggleItemSelection(item._id)}
                >
                  <button className="p-2 text-gray-500">
                    {isScorecard ? (
                      <div className="flex bg-error-500 hover:bg-error-700 text-white text-md font-semibold rounded-md items-center justify-center">
                        <MinusSmIcon className="h-6 w-6" />
                      </div>
                    ) : (
                      <div className="flex bg-blue-700 hover:bg-blue-800 text-white text-md font-semibold rounded-md items-center justify-center">
                        <PlusSmIcon className="h-6 w-6" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemList;
