import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DashboardScorecard({ scorecard, questionnaireId }) {
  const navigate = useNavigate();
  const memberScores = useSelector(
    (state) => state.scorecards.selectedScorecard?.memberScores
  );
  const currentUser = useSelector((state) => state.customerAuth.user);

  // Check if the current user's email is in memberScores
  const isScorecardComplete = memberScores?.some(
    (member) => member.email === currentUser?.email
  );

  // Handlers for buttons
  const handleEditClick = () => {
    navigate(`/create-demo-scorecard?qid=${questionnaireId}`);
  };

  const handleScoreAndReview = () => {
    navigate(`/score-vendors?qid=${questionnaireId}`);
  };

  return (
    <div className="border rounded-md p-0 shadow-sm bg-white">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 p-4 border-b">
        <h2 className="text-lg font-semibold">Scorecard</h2>
        {scorecard ? (
          <button
            onClick={handleScoreAndReview}
            className="bg-blue-700 text-white rounded-md px-4 py-2 text-sm font-medium hover:bg-blue-500"
          >
            Score and Review
          </button>
        ) : (
          <button
            onClick={handleEditClick}
            className="bg-white border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Create
          </button>
        )}
      </div>

      {/* Content based on scorecard existence */}
      {!scorecard ? (
        <div className="text-center p-6">
          <p className="text-base font-semibold text-gray-900">No Scorecard</p>
          <p className="text-sm text-gray-500">
            Create your scorecard to choose a vendor with your teammates.
          </p>
        </div>
      ) : (
        <div className="flex justify-between items-start p-4">
          <div className="flex w-full items-center space-x-2">
            <div className="flex flex-col w-full items-start gap-1">
              <div>
                <span className="text-sm font-medium text-gray-900">
                  Team Scorecard
                </span>
              </div>
              <div
                className={`flex items-center text-xs p-1 px-3 justify-center rounded-full space-x-2 ${
                  isScorecardComplete
                    ? "bg-success-100 text-success-600"
                    : "bg-warning-100 text-warning-600"
                }`}
              >
                <span
                  className={`h-2 w-2 rounded-full ${
                    isScorecardComplete ? "bg-success-400" : "bg-warning-400"
                  }`}
                />
                <span>{isScorecardComplete ? "Complete" : "Incomplete"}</span>
              </div>
            </div>
          </div>
          <button
            onClick={handleEditClick}
            className="bg-white border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
}
