import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVendor,
  initForm,
  setCategories,
  setInitVendors,
  setScoreCardData,
  setScorecardTemplate,
  updateScore,
} from "../../../redux/scoreVendors/scoreVendorsSlice";
import Rating from "./Rating";
import AddCommentBtn from "./AddCommentBtn";
import CommentPopup from "./CommentPopup";
import { useLocation } from "react-router-dom";
import { getScorecardByQuestionnaireId } from "../../../redux/scorecards/scorecardsThunk";

const ScorecardForm = () => {
  const dispatch = useDispatch();
  const currentVendor = useSelector(
    (state) => state.scoreVendors.currentVendor
  );

  const scoreCardData = useSelector(
    (state) => state.scoreVendors.scoreCardData
  );

  const currentUser = useSelector((state) => state.customerAuth.user);

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const [displayCard, setDisplayCard] = useState();

  const newScoreCardData = useSelector(
    (state) => state.scorecards.selectedScorecard?.scoreCardData
  );

  const scorecardTemplate = useSelector(
    (state) => state.scoreVendors.scorecardTemplate
  );

  const currentVendorData = useSelector(
    (state) => state.questionnaire.matchReportScores.selectedVendors
  );

  // Function to create a blank scoring template from the scorecard data
  function createScoringTemplateFromScorecard(scoreCardData) {
    // Step 1: Convert raw scorecard data into the desired format.
    const convertedArrayData = scoreCardData.map((item) => ({
      category: item.functionalArea, // Mapping 'functionalArea' to 'category'
      items: [
        {
          requirement: item.name, // Using 'name' as the requirement
          score: -1, // Default score set to -1
          importance: -1, // Default importance set to -1
          comment: "", // Default comment as an empty string
        },
      ],
    }));

    // Step 2: Merge categories with the same name
    const mergedCategories = convertedArrayData.reduce((acc, current) => {
      const found = acc.find((item) => item.category === current.category);
      if (found) {
        found.items = found.items.concat(current.items); // Combine the items arrays
      } else {
        acc.push(current); // Add the new category
      }
      return acc;
    }, []);

    return mergedCategories;
  }

  // Function to create vendor-specific score templates from the blank template
  function createVendorScoreTemplatesFromTemplate(template, vendors) {
    // Initialize an empty array to store all vendor-specific templates
    let vendorTemplates = [];

    // Loop through each vendor
    vendors.forEach((vendor) => {
      // For each vendor, map through the template to create a new entry for each category
      const templatesForVendor = template.map((category) => ({
        vendor: vendor, // Set the vendor for this iteration
        category: category.category, // Use the existing category
        items: [...category.items], // Copy all items for this category
      }));

      // Concatenate the templates for this vendor to the main array
      vendorTemplates = vendorTemplates.concat(templatesForVendor);
    });

    return vendorTemplates;
  }

  useEffect(() => {
    if (currentVendorData.length > 0) {
      try {
        const scorecardData =
          typeof newScoreCardData === "string"
            ? JSON.parse(newScoreCardData)
            : newScoreCardData;

        const vendors = currentVendorData;

        const scoringTemplate =
          createScoringTemplateFromScorecard(scorecardData);

        const allVendorScoreTemplates = createVendorScoreTemplatesFromTemplate(
          scoringTemplate,
          vendors
        );

        setDisplayCard(allVendorScoreTemplates); // Assuming you want to set this to state for rendering
        dispatch(setScorecardTemplate(allVendorScoreTemplates));
        dispatch(setInitVendors(currentVendorData));
        dispatch(changeVendor(currentVendorData[0]));
      } catch (error) {
        console.error(
          "Error parsing scorecard data or processing vendors:",
          error
        );
      }
    }
  }, [newScoreCardData, selectedScorecard]);

  useEffect(() => {
    if (
      scorecardTemplate.length > 0 &&
      selectedScorecard &&
      selectedScorecard.memberScores.length > 0
    ) {
      const currentUserEmail = currentUser.email;

      // Step 1: Find the scorecard data for the current user by email
      const currentUserScoreObj = selectedScorecard.memberScores.find(
        (member) => member.email === currentUserEmail
      );

      const currentUserScores = currentUserScoreObj
        ? JSON.parse(currentUserScoreObj.scores)
        : [{}];

      // Step 2: Merge the user's scores with the scorecard template
      const userScoresOnTemplate = scorecardTemplate.map((templateEntry) => {
        // Find the matching score data for the current template entry
        const matchingUserScores = currentUserScores.find(
          (score) =>
            score.vendor === templateEntry.vendor &&
            score.category === templateEntry.category
        );

        if (matchingUserScores) {
          // If a match is found, replace the 'items' in the template with those from the user scores
          return {
            ...templateEntry,
            items: matchingUserScores.items.map((item) => {
              // Match individual items by requirement to assign scores
              const userItem = matchingUserScores.items.find(
                (userItem) => userItem.requirement === item.requirement
              );
              if (userItem) {
                return {
                  ...item,
                  score: userItem.score,
                  importance: userItem.importance,
                  comment: userItem.comment,
                };
              }
              return item; // Return the template item if no user item matches
            }),
          };
        }
        return templateEntry; // Return the template entry as-is if no matching user scores are found
      });

      // Now that we have the user scores combined onto the template, we can use these for display.
      // We have a useEffect on displayCard which also propagates this to redux
      setDisplayCard(userScoresOnTemplate);
    }
  }, [scorecardTemplate]);

  useEffect(() => {
    if (displayCard?.length > 0) {
      dispatch(setScoreCardData(displayCard));
    }
  }, [displayCard]);

  const [scorecardCategories, setScorecardCategories] = useState([]);

  // UseEffect to load scorecard based on URL ID
  useEffect(() => {
    dispatch(initForm());
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    dispatch(getScorecardByQuestionnaireId({ id: qid }));
  }, [dispatch]);

  // We need to set the "categories" to populate the initial render. Then, the real magic begins.
  useEffect(() => {
    // Block if needed
    if (!newScoreCardData || !currentUser) return;

    const newScoreCardDataParsed = JSON.parse(newScoreCardData);

    // This extracts the category + item data from the scorecardData.
    function transformData(data) {
      // Group data by 'functionalArea' into an object map
      const groupedByCategory = data.reduce((acc, item) => {
        const key = item.functionalArea;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item.name);
        return acc;
      }, {});

      // Convert the grouped object map into the desired array format with 'items' key
      const transformedArray = [];
      Object.keys(groupedByCategory).forEach((category) => {
        const categoryObject = {
          category: category,
          items: groupedByCategory[category],
        };
        transformedArray.push(categoryObject);
      });

      return transformedArray;
    }

    const extractedCategoryData = transformData(newScoreCardDataParsed);

    // Set these locally, and dispatch an update to redux
    setScorecardCategories(extractedCategoryData);
    dispatch(setCategories(extractedCategoryData));
  }, [newScoreCardData]);

  const [activeComment, setActiveComment] = useState(null);

  const handleUserResponse = (
    questionId,
    value,
    questionText,
    questionCategory
  ) => {
    dispatch(
      updateScore({ questionId, value, questionText, questionCategory })
    );
  };

  const openCommentPopup = (
    questionId,
    currentComment,
    questionCategory,
    questionText
  ) => {
    setActiveComment({
      questionId,
      currentComment,
      questionCategory,
      questionText,
    });
  };

  const closeCommentPopup = () => {
    setActiveComment(null);
  };

  return (
    <div className="px-4 pb-32">
      {scorecardCategories.map((category, categoryIndex) => {
        const currentCategoryData = scoreCardData.find(
          (data) =>
            data.vendor === currentVendor && data.category === category.category
        );

        return (
          <div key={categoryIndex} className="">
            <div className="mb-4 mt-8 text-2xl font-bold">
              {category.category}
            </div>
            <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white">
              {category.items.map((requirement, index) => {
                const currentItem = currentCategoryData?.items.find(
                  (item) => item.requirement === requirement
                );
                return (
                  <div
                    key={index}
                    className={`p-3 flex items-center space-x-3 ${
                      index === 0
                        ? "rounded-t-lg border-t border-l border-r"
                        : "border-l border-r"
                    } ${
                      index === category.items.length - 1
                        ? "rounded-b-lg border-b border-l border-r"
                        : "border-l border-r"
                    }`}
                  >
                    <div className="text-sm flex-1 grow">{requirement}</div>
                    <div className="flex md:space-x-3 md:flex-row flex-col space-y-3 md:space-y-0">
                      <div className="flex items-center space-x-2 text-sm justify-between">
                        <div>Score:</div>
                        <Rating
                          score={currentItem?.score}
                          handleUserResponse={handleUserResponse}
                          questionId={`score-${category.category}-${index}`}
                          questionText={requirement}
                          questionCategory={category.category}
                        />
                      </div>
                      <div className="flex items-center space-x-2 text-sm">
                        <div>Importance:</div>
                        <Rating
                          score={currentItem?.importance}
                          handleUserResponse={handleUserResponse}
                          questionId={`importance-${category.category}-${index}`}
                          questionText={requirement}
                          questionCategory={category.category}
                        />
                      </div>
                      <div
                        onClick={() =>
                          openCommentPopup(
                            `comment-${category.category}-${index}`,
                            currentItem?.comment,
                            category.category,
                            requirement
                          )
                        }
                      >
                        <AddCommentBtn comment={currentItem?.comment} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {activeComment && (
        <CommentPopup
          questionId={activeComment.questionId}
          questionText={activeComment.questionText}
          questionCategory={activeComment.questionCategory}
          initialValue={activeComment.currentComment}
          onClose={closeCommentPopup}
        />
      )}
    </div>
  );
};

export default ScorecardForm;
