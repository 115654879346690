import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react";
import ChatGPTLogo from "../../../assets/ChatGPTLogo";

const AiGenerateModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Generate Scorecard with AI",
  confirmText = "Yes, generate with AI",
  cancelText = "No, I'll do it manually",
}) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog
              onClose={onClose}
              className="fixed inset-0 bg-gray-500/80 transition-opacity"
            />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <DialogTitle
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900 text-center"
                >
                  NEW! Generate Scorecard with AI
                </DialogTitle>
                <div className="py-4 space-y-3">
                  <p className="text-sm text-gray-500 text-center">
                    Shortlist Match's AI model has been trained on hundreds of
                    match report criteria, helping customers evaluate vendors
                    based on industry best practices. Would you like our AI
                    consultant to automatically generate a scorecard for you to
                    evaluate vendors with?
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 flex flex-col w-full items-stretch justify-end">
                <button
                  type="button"
                  className="min-w-32 space-x-2 inline-flex justify-center rounded-md border border-transparent shadow-sm p-4 bg-gray-800 text-base font-medium text-white transition-all hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                  onClick={onConfirm}
                >
                  <ChatGPTLogo className="h-4 w-4" />
                  <span>{confirmText}</span>
                </button>
                <button
                  type="button"
                  className="mt-3 min-w-32 inline-flex justify-center rounded-md px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm"
                  onClick={onClose}
                >
                  No thanks, I'll do it manually
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AiGenerateModal;
