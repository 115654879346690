import { createAsyncThunk } from "@reduxjs/toolkit";
import ShortListAdviceAPI from "../ShortListAdviceAPI"; // Replace with your actual API utility
import CustomerAPI from "../CustomerAPI";

export const getSimplifiedRequirementsList = createAsyncThunk(
  "requirements/getSimplifiedRequirementsList",
  async (_, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await ShortListAdviceAPI.get(
        "/api/requirements/getSimplifiedRequirementsList"
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const populateRequirementDetails = createAsyncThunk(
  "requirements/populateRequirementDetails",
  async ({ id }, { getState, rejectWithValue }) => {
    const state = getState();

    // Check in scoreVendors.availableRequirements
    let requirement = state.scoreVendors.availableRequirements.find(
      (req) => req._id === id && req.description
    );

    // If not found, check in scoreVendors.selectedScorecardRequirements
    if (!requirement) {
      requirement = state.scoreVendors.selectedScorecardRequirements.find(
        (req) => req._id === id && req.description
      );
    }

    // If requirement is found locally with a description, return it
    if (requirement) {
      return requirement;
    }

    // If the requirement is not found locally or doesn't have a description, load details from the cloud
    try {
      const response = await ShortListAdviceAPI.get("/api/requirements/" + id);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const loadScorecardByQuestionnaireId = createAsyncThunk(
  "requirements/loadScorecardByQuestionnaireId",
  async ({ qid }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await ShortListAdviceAPI.get("/api/scorecards/" + qid);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const submitScorecardScores = createAsyncThunk(
  "requirements/submitScorecardScores",
  async ({ qid, body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        "/api/scorecards/submitScorecardScores/" + qid,
        body
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const selectWinner = createAsyncThunk(
  "requirements/selectWinner",
  async ({ qid, body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        "/api/scorecards/selectWinner/" + qid,
        body
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const requestAiScorecard = createAsyncThunk(
  "scorecards/requestAiScorecard",
  async ({ qid }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        `/api/scorecards/requestAiScorecard/${qid}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
