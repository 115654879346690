import React, { useState, useEffect } from "react";
import SlideOutDetail from "./components/SlideOutDetail"; // The slide-out detail view
import ItemList from "./components/ItemList";
import ActionButton from "../../components/PageHeader/components/ActionButton";
import SingleLineTextInput from "../../components/SingleLineTextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimplifiedRequirementsList,
  populateRequirementDetails,
  requestAiScorecard,
} from "../../redux/scoreVendors/scoreVendorsThunk";
import CustomerRequirementsCreator from "./components/CustomerRequirementsCreator/CustomerRequirementsCreator";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerBackToShortlists from "../../components/CustomerBackToShortlists";
import {
  getScorecardByQuestionnaireId,
  saveScorecardDataByQuestionnaireId,
} from "../../redux/scorecards/scorecardsThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateCustomerStatus } from "../../redux/customerAuth/customerAuthSlice";
import { setSelectedScorecardRequirements } from "../../redux/scoreVendors/scoreVendorsSlice";
import CustomRequirementModal from "../../components/CustomRequirementModal/CustomRequirementModal";
import Spinner from "../../components/Spinner";
import ScorecardImages from "./components/ScorecardImages";
import { BanIcon, PencilAltIcon } from "@heroicons/react/outline";
import AiLoading from "./components/AiLoading";
import AiGenerateModal from "./components/AiGenerateModal";
import { PlusIcon } from "@heroicons/react/solid";

const CustomerCreateDemoScorecard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const availableRequirements = useSelector(
    (state) => state.scoreVendors.availableRequirements
  );
  const availableRequirementsStatus = useSelector(
    (state) => state.scoreVendors.availableRequirementsStatus
  );
  const selectedScorecardRequirements = useSelector(
    (state) => state.scoreVendors.selectedScorecardRequirements
  );

  const requirementDetailView = useSelector(
    (state) => state.scoreVendors.requirementDetailView
  );

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const aiScorecardStatus = useSelector(
    (state) => state.scoreVendors.aiScorecardStatus
  );
  const scoreVendorsStatus = useSelector((state) => state.scoreVendors.status);
  const scorecardsStatus = useSelector((state) => state.scorecards.status);

  // setSelectedScorecardRequirements
  // UseEffect to load scorecard based on URL ID
  useEffect(() => {
    if (selectedScorecard?.scoreCardData) {
      dispatch(
        setSelectedScorecardRequirements(
          JSON.parse(selectedScorecard.scoreCardData)
        )
      );
    }
  }, [selectedScorecard]);

  useEffect(() => {
    if (
      selectedScorecardRequirements?.length === 0 &&
      availableRequirementsStatus === "succeeded"
    ) {
      console.log("USER HAS NO REQUIRMEENTS");
      // TODO: Show popup modal which says something like:
      // "NEW! Would you like our custom AI Model to build your scorecard for you?"
      // YES <-- AI genrates their scorecard, very pronounced
      // No thanks, I'll start from scratch <-- barely noticeable
      handleOpenAIModal();
    }
  }, [selectedScorecardRequirements, availableRequirementsStatus]);

  useEffect(() => {
    // UseEffect to load scorecard based on URL ID
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    dispatch(getScorecardByQuestionnaireId({ id: qid }));

    // If we are on our initial load, get all of the available requirements
    if (availableRequirementsStatus == "init") {
      dispatch(getSimplifiedRequirementsList());
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState(requirementDetailView);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const showEditor = useSelector((state) => state.requirements.showEditor);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsSlideOutOpen(true);
    dispatch(populateRequirementDetails({ id: item._id }));
  };

  const closeSlideOut = () => setIsSlideOutOpen(false);

  // Filter availableRequirements based on searchVal
  const filteredAvailableRequirements = searchVal
    ? availableRequirements.filter((req) =>
        req.name.toLowerCase().includes(searchVal.toLowerCase())
      )
    : availableRequirements;

  const handleSaveScorecard = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    const saveScorecardData = {
      qid: qid,
      body: {
        scoreCardData: selectedScorecardRequirements,
      },
    };

    try {
      // Dispatch the action and unwrap the result
      const result = await dispatch(
        saveScorecardDataByQuestionnaireId(saveScorecardData)
      ).then(unwrapResult);

      // If the above line didn't throw, the action was successful
      dispatch(updateCustomerStatus("scorecardCreated"));
      navigate("/my-shortlists");
    } catch (error) {
      // Do not navigate away from page.
      console.error("Failed to save scorecard:", error);
    }
  };

  const [isCustomRequirementOpen, setIsCustomRequirementOpen] = useState(false);
  const handleConfirmCustomRequirement = () => {
    // Handle the confirm action
    setIsCustomRequirementOpen(false);
    console.log("Confirmed!");
  };

  const handleCloseCustomRequirement = () => {
    // Handle the close action
    setIsCustomRequirementOpen(false);
  };

  // Handle Loading States
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (scoreVendorsStatus === "loading" || scorecardsStatus === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [scoreVendorsStatus, scorecardsStatus]);

  const handleAIGen = () => {
    dispatch(requestAiScorecard({ qid: selectedScorecard.questionnaireId }));
    const groupedRequirements = {};
    availableRequirements.forEach((requirement) => {
      const { name, functionalArea } = requirement;

      // If the functional area doesn't exist in the object, create an empty array
      if (!groupedRequirements[functionalArea]) {
        groupedRequirements[functionalArea] = [];
      }

      // Push the requirement with 'name' and 'include' set to false initially
      groupedRequirements[functionalArea].push({ name, include: false });
    });
  };

  const [isAIModalOpen, setIsAIModalOpen] = useState(false);

  const handleCreateWithAIButtonClick = () => {
    const userConfirmation = window.prompt(
      'Are you sure? This will overwrite your existing scorecard. Please type "yes" to confirm.'
    );

    if (userConfirmation && userConfirmation.toLowerCase() === "yes") {
      handleAIGen();
    } else {
      console.log("AI generation canceled by the user.");
    }
  };

  const handleAIGenerate = () => {
    handleAIGen();
    setIsAIModalOpen(false);
  };

  const handleOpenAIModal = () => {
    setIsAIModalOpen(true);
  };

  const handleCloseAIModal = () => {
    setIsAIModalOpen(false);
  };

  return showEditor ? (
    <div className="overflow-hidden">
      <CustomerRequirementsCreator />
    </div>
  ) : (
    <div className="flex flex-col p-6">
      <div className="flex w-full justify-between">
        <CustomerBackToShortlists />
        <ActionButton
          text="Save Scorecard"
          func={() => handleSaveScorecard()}
        />
      </div>
      <div className="flex flex-col gap-2 mb-4">
        <CustomRequirementModal
          isOpen={isCustomRequirementOpen}
          onClose={handleCloseCustomRequirement}
          onConfirm={handleConfirmCustomRequirement}
          title="Create Custom Requirement"
          confirmText="OK"
          cancelText="Cancel"
        />
        <AiGenerateModal
          isOpen={isAIModalOpen}
          onClose={handleCloseAIModal}
          onConfirm={handleAIGenerate}
        />
      </div>

      <div className="flex flex-col pb-8">
        <h1 className="text-2xl font-bold">Create Scorecard</h1>
        <p className="text-gray-700 text-sm">
          You will be evaluating vendors based on your own custom criteria.
          Select which criteria to include, or create custom criteria to
          evaluate vendors with.
        </p>
      </div>
      <div className="flex space-x-3 w-full">
        <div className="w-1/2 lg:w-1/3 p-4 flex flex-col bg-white rounded-lg border border-gray-200 shadow-lg">
          <div className="flex justify-between pb-5">
            <h1 className="text-2xl font-semibold mb-1">
              Available Requirements
            </h1>
            {/* <p className="text-sm text-gray-700">
            Choose which requirements you'd like to evaluate vendors for.
          </p> */}
            <div className="space-y-6">
              {/* <ActionButton
              text="Custom"
              func={() => setIsCustomRequirementOpen(true)}
            /> */}
              <button
                className="flex space-x-2 bg-blue-700 hover:bg-blue-800 text-white text-md font-semibold p-2 rounded-md px-6 items-center justify-center"
                onClick={() => setIsCustomRequirementOpen(true)}
              >
                <PlusIcon className="h-5 w-5" />
                <span>Custom</span>
              </button>
            </div>
          </div>
          <div className="pb-3">
            <SingleLineTextInput
              label="Filter Requirements"
              inputVal={searchVal}
              inputUpdateFunc={(value) => setSearchVal(value)}
            />
          </div>
          {availableRequirementsStatus === "loading" ? (
            <div className="w-full h-full flex items-center justify-center p-6">
              <Spinner />
            </div>
          ) : (
            <ItemList
              isScorecard={false}
              items={filteredAvailableRequirements}
              onItemSelected={handleItemClick}
            />
          )}
        </div>
        <div className="w-1/2 lg:w-2/3 p-4 flex flex-col bg-white rounded-lg border border-gray-200 shadow-lg">
          <div className="mb-6 space-y-2">
            <div className="w-full flex items-center justify-between">
              <h1 className="text-2xl font-semibold ">Current Scorecard</h1>

              <button
                className="bg-gray-900 hover:bg-gray-700 text-white text-md font-semibold p-2 rounded-md px-4 flex items-center space-x-2"
                onClick={() => handleCreateWithAIButtonClick()}
              >
                <PencilAltIcon className="h-5 w-5" />
                <span>Create with AI</span>
              </button>
            </div>
            <ScorecardImages />
          </div>
          {aiScorecardStatus === "loading" ? (
            <AiLoading />
          ) : (
            <ItemList
              isScorecard={true}
              items={selectedScorecardRequirements}
              onItemSelected={(item) => handleItemClick(item)}
            />
          )}
        </div>
      </div>
      <SlideOutDetail
        item={selectedItem}
        onClose={closeSlideOut}
        isOpen={isSlideOutOpen}
      />
    </div>
  );
};

export default CustomerCreateDemoScorecard;
