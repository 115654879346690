import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShortlistCard from "./ShortlistCard";

const ShortlistCardListView = () => {
  const completedQuestionnaires = useSelector(
    (state) => state.customerAuth?.customer?.completedQuestionnaires
  );

  return (
    <div className="">
      {completedQuestionnaires?.map((questionnaire) => (
        <ShortlistCard questionnaire={questionnaire} />
      ))}
    </div>
  );
};

export default ShortlistCardListView;
