import React from "react";
import Timeline from "./ShortlistCard/Timeline";
import CustomerStepDropdown from "./ShortlistCard/CustomerStepDropdown";

export default function CardHeader({ createdAt, categories }) {
  return (
    <div className="p-4 w-full flex flex-col space-between space-y-2  md:flex-row md:space-y-0">
      <div className="w-full">
        <div className="text-2xl font-bold text-gray-800">Shortlist 1</div>
        <div className="text-sm font-semibold text-gray-600">
          Created on:{" "}
          <span className="font-medium">
            {createdAt.toLocaleDateString()} at {createdAt.toLocaleTimeString()}
          </span>
        </div>
      </div>
      <CustomerStepDropdown />
    </div>
  );
}
