import React, { useState } from "react";
import { useSelector } from "react-redux";

const ScorecardImages = ({ scores }) => {
  const selectedVendorImages = useSelector(
    (state) => state.questionnaire.gptScores.matchReportSelectedImages || []
  );

  const [visibleCategoryIndexes, setVisibleCategoryIndexes] = useState({});

  const toggleVisibility = (index) => {
    setVisibleCategoryIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div
      key={0}
      className="shadow-md p-0 md:p-3 rounded-lg mb-4 bg-grayBlue-50"
    >
      <div className="font-semibold">Vendors:</div>
      <div
        className=" flex items-end md:items-center cursor-pointer flex-col md:flex-row w-full"
        onClick={() => toggleVisibility(0)}
      >
        <div className="w-full grid grid-cols-3 ">
          {selectedVendorImages.map((images, idx) => {
            return (
              <div
                key={idx}
                className="font-medium text-center border-solid border-0 border-l border-gray-100"
              >
                <div className="bg-grayBlue-50 flex justify-center p-4 whitespace-nowrap font-semibold text-[1rem] md:text-[1.125rem]">
                  <div className="max-h-[200px] p-1 rounded-md border shadow-sm aspect-square bg-white flex items-center justify-center">
                    <img
                      src={selectedVendorImages[idx]}
                      className="object-contain h-full w-full"
                      alt={"logo"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScorecardImages;
