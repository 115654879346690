import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getScorecardByQuestionnaireId } from "../../../../redux/scorecards/scorecardsThunk";
import MiniMemberRow from "./MiniMemberRow";
import Button from "../../../../components/Button";

export default function DashboardMembersList({ questionnaireId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.customerAuth.user);

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    if (!selectedScorecard) {
      dispatch(getScorecardByQuestionnaireId({ id: questionnaireId }));
    } else {
      const currentUserEmail = currentUser.email;
      const filteredTeamMembers = selectedScorecard.teamMembers
        .filter((member) => member.email !== currentUserEmail)
        .map((member) => {
          const memberScore = selectedScorecard.memberScores.find(
            (score) => score.email === member.email
          );
          return {
            ...member,
            completedScorecard: memberScore ? true : false,
          };
        });

      setTeamMembers(filteredTeamMembers);
    }
  }, [selectedScorecard, currentUser, dispatch, questionnaireId]);

  const handleEditTeam = () => {
    navigate(`/create-team?qid=${questionnaireId}`);
  };

  return (
    <div className="border rounded-md p-0 shadow-sm bg-white">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4 p-4 border-b">
        <h2 className="text-lg font-semibold">Team Members</h2>
        <button
          onClick={handleEditTeam}
          className="bg-white border border-gray-300 rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Edit
        </button>
      </div>

      {/* Team Members List */}
      <div className="px-4">
        {teamMembers.length === 0 ? (
          <p className="text-center text-sm text-gray-500 p-6 pb-12">
            No Team Members.
          </p>
        ) : (
          <div className="flex flex-col divide-y divide-gray-200">
            {teamMembers.map((member, index) => (
              <MiniMemberRow
                key={index}
                index={index}
                firstName={member.firstName}
                lastName={member.lastName}
                status={member.status}
                completedScorecard={member.completedScorecard}
                email={member.email}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
