import React, { useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";

export default function CustomerStepDropdown() {
  const navigate = useNavigate();
  const customerStep = useSelector(
    (state) => state.customerAuth.customer?.status
  );
  const questionnaireId = useSelector(
    (state) => state.questionnaire.matchReportScores?.questionnaireId
  );
  const scorecards = useSelector((state) => state.scorecards.scorecards);
  const [currentScorecard, setCurrentScorecard] = useState(null);
  const [steps, setSteps] = useState([]);
  const [customerStepData, setCustomerStepData] = useState();

  // Fetch memberScores and currentUser to check if the current user has evaluated vendors
  const memberScores = useSelector(
    (state) => state.scorecards.selectedScorecard?.memberScores
  );
  const currentUser = useSelector((state) => state.customerAuth.user);

  // Check if the current user's email is in memberScores (i.e., if the scorecard is complete for them)
  const isScorecardComplete = memberScores?.some(
    (member) => member.email === currentUser?.email
  );

  // Calculate percentage of tasks completed for the circular progress
  const completedSteps = steps.filter(
    (step) => step.state === "completed"
  ).length;
  const totalSteps = steps.length;
  const progress = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

  // Adjust the stroke-dasharray based on the progress (for the circle)
  const circumference = 20 * 2 * Math.PI; // Assuming radius is 20
  const offset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    let foundScorecard = scorecards.find(
      (scorecard) => scorecard.questionnaireId._id === questionnaireId
    );

    if (!foundScorecard) {
      foundScorecard = scorecards.find(
        (scorecard) => scorecard.questionnaireId === questionnaireId
      );
    }

    if (foundScorecard) {
      setCurrentScorecard(foundScorecard);
    }
  }, [scorecards, questionnaireId]);

  const initialSteps = [
    { name: "Take Questionnaire", state: "disabled", url: null },
    {
      name: "Create Team",
      state: "disabled",
      url: `/create-team?qid=${questionnaireId}`,
      action: "Invite",
    },
    {
      name: "Create Vendor Scorecard",
      state: "disabled",
      url: `/create-demo-scorecard?qid=${questionnaireId}`,
      action: "Create",
    },
    {
      name: "Evaluate Vendors",
      state: "disabled",
      url: `/score-vendors?qid=${questionnaireId}`,
    },
    {
      name: "Team Review + Select Winner",
      state: "disabled",
      url: `/view-team-results?qid=${questionnaireId}`,
    },
  ];

  useEffect(() => {
    const statusToIndexMapping = {
      resultsSent: 0,
      teamCreated: 1,
      scorecardCreated: 2,
      evaluationsCollected: 3,
      winnerSelected: 4,
    };

    const currentIndex = currentScorecard?.selectedWinner
      ? 4
      : statusToIndexMapping[customerStep] ?? -1;

    setCustomerStepData(initialSteps[currentIndex + 1]);

    let updatedSteps = initialSteps.map((step, index) => {
      if (index <= currentIndex) {
        return { ...step, state: "completed" };
      } else if (index === currentIndex + 1) {
        return { ...step, state: "current" };
      } else {
        return { ...step, state: "disabled" };
      }
    });

    // Update "Evaluate Vendors" step as completed if the user has already evaluated vendors
    if (isScorecardComplete) {
      updatedSteps[3].state = "completed"; // Mark "Evaluate Vendors" as completed
    }

    if (currentIndex === 2) {
      updatedSteps[4].state = "current";
    }

    setSteps(updatedSteps);
  }, [customerStep, questionnaireId, currentScorecard, isScorecardComplete]);

  const handleItemClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center space-x-2">
        {/* Circular Progress Bar */}

        <MenuButton className="inline-flex w-64 justify-between gap-x-1.5 rounded-md bg-white p-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <div className="flex items-center space-x-2">
            <svg className="h-6 w-6" viewBox="0 0 50 50">
              <circle
                className="text-gray-300"
                strokeWidth="8"
                stroke="currentColor"
                fill="transparent"
                r="20"
                cx="25"
                cy="25"
              />
              <circle
                className="text-blue-600"
                strokeWidth="8"
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                stroke="currentColor"
                fill="transparent"
                r="20"
                cx="25"
                cy="25"
              />
            </svg>
            <span>
              {completedSteps} of {totalSteps} Tasks Complete
            </span>
          </div>
          <ChevronDownIcon
            aria-hidden="true"
            className="-mr-1 h-5 w-5 text-gray-400"
          />
        </MenuButton>
      </div>

      <MenuItems className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {steps.map((step, index) => (
            <MenuItem key={index}>
              {({ active }) => (
                <div
                  onClick={() => handleItemClick(step.url)}
                  className={`flex justify-between items-center px-4 py-2 text-sm cursor-pointer ${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {step.state === "completed" && (
                      <CheckCircleIcon className="h-5 w-5 text-success-500" />
                    )}
                    {step.state === "current" && (
                      <ExclamationCircleIcon className="h-5 w-5 text-blue-600" />
                    )}
                    {step.state === "disabled" && (
                      <XCircleIcon className="h-5 w-5 text-gray-400" />
                    )}
                    <span>{step.name}</span>
                  </div>
                  {step.action && step.state === "current" && (
                    <span className="text-blue-600">{step.action}</span>
                  )}
                </div>
              )}
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
}
