import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminQuestionnaire,
  submitQuestionnaire,
  getGptScoresForQuestionnaire,
  adminSendResultsReady,
  getQuestionnaire,
  setMatchReportSelectedImages,
} from "./questionnaireThunk";
import toast from "react-hot-toast";

// This is what manages the actual taking of the questionnaire, storing answers
// as well as managing what the next + previous questions are.

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    categories: [],
    currentForm: null,
    nextForm: null,
    prevForm: null,
    currentCategoryIndex: 0,
    currentSubcategoryIndex: 0,
    selectedCategories: ["Company Details"],
    answers: [],
    gptScores: {
      isInitialLoad: true,
      selectedVendors: {},
      questionnaireId: null,
      overallScores: {},
      categories: [],
      matchReportSelectedVendors: ["", "", ""],
      matchReportSelectedImages: ["", "", ""],
      matchReportContactInfo: [
        {
          contactName: "",
          contactEmail: "",
          contactPhone: "",
        },
        { contactName: "", contactEmail: "", contactPhone: "" },
        { contactName: "", contactEmail: "", contactPhone: "" },
      ],
    },
    matchReportScores: {
      questionnaireId: null,
      selectedVendors: ["", "", ""],
      contactInfo: [
        {
          vendorName: "",
          contactName: "",
          contactEmail: "",
          contactPhone: "",
        },
        { contactName: "", contactEmail: "", contactPhone: "" },
        { contactName: "", contactEmail: "", contactPhone: "" },
      ],
      overallScores: {},
      categories: [],
    },
  },
  reducers: {
    // Based on the user's selection in the initial part of the quiz, we set these
    // up to calculate next and prev question logic.
    initQuestionnaire: (state, { payload }) => {
      state.currentCategoryIndex = 0;
      state.currentSubcategoryIndex = 0;
    },
    overrideMatchReportScore: (state, { payload }) => {
      console.log("Redux :: overrideMatchReportScore");
      console.log(payload);

      const { newScore, targetCategory, targetRequirement, targetVendor } =
        payload;

      // Find the category
      const categoryIndex = state.matchReportScores.categories.findIndex(
        (category) => category.title === targetCategory
      );

      if (categoryIndex === -1) {
        console.error("Category not found:", targetCategory);
        return;
      }

      const category = state.matchReportScores.categories[categoryIndex];

      // Find the question within the category
      const questionIndex = category.questionScores.findIndex(
        (questionScore) => questionScore.question === targetRequirement
      );

      if (questionIndex === -1) {
        console.error("Question not found:", targetRequirement);
        return;
      }

      const questionScore = category.questionScores[questionIndex];

      // Update the score for the target vendor
      questionScore[targetVendor] = newScore;

      // Propagate the changes back to the state
      state.matchReportScores.categories[categoryIndex].questionScores[
        questionIndex
      ] = questionScore;

      // Calculate new average for the targetVendor across all questionScores in the category
      let totalScore = 0;
      let scoreCount = 0;

      category.questionScores.forEach((score) => {
        const vendorScore = score[targetVendor];
        if (vendorScore !== undefined) {
          totalScore += vendorScore;
          scoreCount++;
        }
      });

      const averageScore = scoreCount > 0 ? totalScore / scoreCount : 0;

      // Set the new average score for the targetVendor in the current category
      state.matchReportScores.categories[categoryIndex].categoryScores[
        targetVendor
      ] = averageScore;

      console.log(
        `New average score for vendor ${targetVendor} in category ${targetCategory}:`,
        averageScore
      );

      // Calculate the overall average score for the targetVendor across all categories
      let totalCategoryScores = 0;
      let categoryCount = 0;

      state.matchReportScores.categories.forEach((cat) => {
        const categoryVendorScore = cat.categoryScores[targetVendor];
        if (
          categoryVendorScore !== undefined &&
          cat.questionScores.length > 0
        ) {
          totalCategoryScores += categoryVendorScore;
          categoryCount++;
        }
      });

      const overallAverageScore =
        categoryCount > 0 ? totalCategoryScores / categoryCount : 0;

      // Update the overall score for the targetVendor
      state.matchReportScores.overallScores[targetVendor] = overallAverageScore;

      console.log(
        `New overall score for vendor ${targetVendor}:`,
        overallAverageScore
      );
    },

    setMatchReportVendors: (state, { payload }) => {
      state.gptScores.matchReportSelectedVendors[payload.index] = payload.value;
    },

    setMatchReportScores: (state, { payload }) => {
      state.matchReportScores = payload;
    },
    setMatchReportContactInfo: (state, { payload }) => {
      state.gptScores.matchReportContactInfo[payload.index] = payload.value;
    },
    setIsInitialLoad: (state, { payload }) => {
      state.gptScores.isInitialLoad = payload.isInitialLoad;
    },
    setSelectedCategories: (state, { payload }) => {
      state.categories = payload.categories;
    },
    setSelectedVendors: (state, { payload }) => {
      state.gptScores.selectedVendors = payload.selectedVendors;
    },
    setQuestionUserResponse: (state, { payload }) => {
      const { questionId, userResponse } = payload;
      const { categories, currentCategoryIndex, currentSubcategoryIndex } =
        state;

      if (currentCategoryIndex == 0 && currentSubcategoryIndex == 1) {
        state.selectedCategories = [
          ...userResponse,
          "Contact",
          "Company Details",
          "Your Tech Stack",
        ];
      }

      // Assuming that each subcategory has a 'questions' array
      let questions =
        categories[currentCategoryIndex].subcategories[currentSubcategoryIndex]
          .questions;

      // Find the question by ID and update its user response
      const questionIndex = questions.findIndex((q) => q.id === questionId);
      if (questionIndex !== -1) {
        questions[questionIndex].userResponse = userResponse;
      }
    },

    setQuestionUserImportanceRating: (state, { payload }) => {
      const { questionId, userImportanceRating } = payload;
      const { categories, currentCategoryIndex, currentSubcategoryIndex } =
        state;

      // Assuming that each subcategory has a 'questions' array
      let questions =
        categories[currentCategoryIndex].subcategories[currentSubcategoryIndex]
          .questions;

      // Find the question by ID and update its user response
      const questionIndex = questions.findIndex((q) => q.id === questionId);
      if (questionIndex !== -1) {
        questions[questionIndex].userImportanceRating = userImportanceRating;
      }
    },
    handleNextButton: (state, { payload }) => {
      const {
        currentCategoryIndex,
        currentSubcategoryIndex,
        categories,
        selectedCategories,
      } = state;

      // Function to find the next valid category index
      const findNextValidCategoryIndex = (currentIndex) => {
        let nextIndex = currentIndex + 1;
        while (nextIndex < categories.length) {
          if (selectedCategories.includes(categories[nextIndex].title)) {
            return nextIndex;
          }
          nextIndex++;
        }
        return null; // Indicates no valid next category found
      };

      if (
        currentSubcategoryIndex <
        categories[currentCategoryIndex].subcategories.length - 1
      ) {
        return {
          ...state,
          currentSubcategoryIndex: currentSubcategoryIndex + 1,
        };
      } else {
        const nextCategoryIndex =
          findNextValidCategoryIndex(currentCategoryIndex);
        if (nextCategoryIndex !== null) {
          return {
            ...state,
            currentCategoryIndex: nextCategoryIndex,
            currentSubcategoryIndex: 0,
          };
        } else {
          // End of categories, handle accordingly
        }
      }
    },
    handlePrevButton: (state, { payload }) => {
      const {
        currentCategoryIndex,
        currentSubcategoryIndex,
        categories,
        selectedCategories,
      } = state;

      // Function to find the previous valid category index
      const findPrevValidCategoryIndex = (currentIndex) => {
        let prevIndex = currentIndex - 1;
        while (prevIndex >= 0) {
          if (selectedCategories.includes(categories[prevIndex].title)) {
            return prevIndex;
          }
          prevIndex--;
        }
        return null; // Indicates no valid previous category found
      };

      if (currentSubcategoryIndex > 0) {
        return {
          ...state,
          currentSubcategoryIndex: currentSubcategoryIndex - 1,
        };
      } else if (currentCategoryIndex > 0) {
        const prevCategoryIndex =
          findPrevValidCategoryIndex(currentCategoryIndex);
        if (prevCategoryIndex !== null) {
          const prevSubcategoryIndex =
            categories[prevCategoryIndex].subcategories.length - 1;
          return {
            ...state,
            currentCategoryIndex: prevCategoryIndex,
            currentSubcategoryIndex: prevSubcategoryIndex,
          };
        } else {
          // Beginning of categories, handle accordingly
        }
      }
    },
  },
  extraReducers: {
    // --------
    // getAdminQuestionnaire
    // --------
    [getAdminQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.categories = null;
      state.error = "";
    },
    [getAdminQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [getAdminQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.categories = null;
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // submitQuestionnaire
    // --------
    [submitQuestionnaire.pending]: (state) => {
      state.status = "submitting";
      state.error = "";
    },
    [submitQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
    },
    [submitQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // getGptScoresForQuestionnaire
    // --------
    [getGptScoresForQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.error = "";
      state.gptScores.questionnaireId = null;
      state.gptScores.overallScores = {};
      state.gptScores.categories = [];
    },
    [getGptScoresForQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.gptScores.questionnaireId = payload.questionnaireId;
      state.gptScores.overallScores = payload.scores.overallScores;
      state.gptScores.categories = payload.scores.categories;
    },
    [getGptScoresForQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },

    // --------
    // adminSendResultsReady
    // --------
    [adminSendResultsReady.pending]: (state) => {
      state.status = "loading";
      state.error = "";
    },
    [adminSendResultsReady.fulfilled]: (state, { payload }) => {
      state.status = "success";
      toast.success("Sent Customer Email!");
    },
    [adminSendResultsReady.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // getQuestionnaire
    // --------
    [getQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.categories = null;
      state.error = "";
    },
    [getQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [getQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.categories = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // setMatchReportSelectedImages
    // --------
    [setMatchReportSelectedImages.pending]: (state) => {
      state.status = "loading";
    },
    [setMatchReportSelectedImages.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.gptScores.matchReportSelectedImages = payload;
    },
    [setMatchReportSelectedImages.rejected]: (state, { payload }) => {
      state.status = "failed";
    },
  },
});

export const {
  overrideMatchReportScore,
  setMatchReportScores,
  setSelectedCategories,
  setSelectedVendors,
  setIsInitialLoad,
  updateCategory,
  addCategory,
  handleNextButton,
  handlePrevButton,
  initQuestionnaire,
  setQuestionUserResponse,
  setQuestionUserImportanceRating,
  setMatchReportVendors,
  setMatchReportContactInfo,
} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;
