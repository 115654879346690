import { createSlice } from "@reduxjs/toolkit";
import {
  getSimplifiedRequirementsList,
  populateRequirementDetails,
  submitScorecardScores,
  selectWinner,
  requestAiScorecard,
} from "./scoreVendorsThunk";
import toast from "react-hot-toast";

const initialState = {
  vendors: ["Netsuite", "Pigment", "Jedox"],
  currentVendor: "Netsuite",
  categories: [],
  rawScorecardData: null,
  scoreCardData: [],
  availableRequirementsStatus: "init",
  availableRequirements: [],
  selectedScorecardRequirements: [],
  scorecardTemplate: [],
  aiScorecardStatus: "init",
  aiScorecardSuggestions: [],
  requirementDetailView: {
    title: "",
    category: "",
  },
};

export const scoreVendorsSlice = createSlice({
  name: "scoreVendors",
  initialState,
  reducers: {
    setInitVendors: (state, action) => {
      state.vendors = action.payload;
    },
    changeVendor: (state, action) => {
      state.currentVendor = action.payload;
    },
    setScoreCardData: (state, action) => {
      state.scoreCardData = action.payload;
    },
    setScorecardTemplate: (state, action) => {
      state.scorecardTemplate = action.payload;
    },
    setSelectedScorecardRequirements: (state, action) => {
      state.selectedScorecardRequirements = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
      // state.scoreCardData = state.vendors
      //   .map((vendor) =>
      //     action.payload.map((category) => ({
      //       vendor: vendor,
      //       category: category.category,
      //       items: category.items.map((requirement) => ({
      //         requirement: requirement,
      //         score: requirement.score ?? -1,
      //         importance: requirement.importance ?? -1,
      //         comment: requirement.comment ?? "",
      //       })),
      //     }))
      //   )
      //   .flat(); // Flatten the array since map will return an array of arrays
    },
    addRequirementToScorecard: (state, action) => {
      const requirementId = action.payload;
      const requirementIndex = state.availableRequirements.findIndex(
        (req) => req._id === requirementId
      );

      // If the item exists in the list, remove it from our current list
      if (requirementIndex > -1) {
        const [removedRequirement] = state.availableRequirements.splice(
          requirementIndex,
          1
        );

        // Check if this item is already part of the scorecard; if not, add it.
        const currentIndex = state.selectedScorecardRequirements.findIndex(
          (req) => req._id === requirementId
        );
        if (currentIndex === -1) {
          state.selectedScorecardRequirements.push(removedRequirement);
        }
      }
    },
    addCustomRequirementToScorecard: (state, action) => {
      const requirement = action.payload;
      console.log("addCustomRequirement");
      console.log(requirement);
      state.selectedScorecardRequirements.push(requirement);
    },
    removeRequirementFromScorecard: (state, action) => {
      const requirementId = action.payload;
      const requirementIndex = state.selectedScorecardRequirements.findIndex(
        (req) => req._id === requirementId
      );
      if (requirementIndex > -1) {
        const [removedRequirement] = state.selectedScorecardRequirements.splice(
          requirementIndex,
          1
        );
        state.availableRequirements.push(removedRequirement);
      }
    },
    initForm: (state) => {
      state.scoreCardData = state.vendors
        .map((vendor) =>
          state.categories.map((category) => ({
            vendor: vendor,
            category: category.category,
            items: category.items.map((requirement) => ({
              requirement: requirement,
              score: -1,
              importance: -1,
              comment: "",
            })),
          }))
        )
        .flat(); // Flatten the array since map will return an array of arrays
    },
    updateScore: (state, action) => {
      const { questionId, value, questionText, questionCategory } =
        action.payload;
      const [target, category] = questionId.split("-");
      const currentVendor = state.currentVendor;

      // If the target is 'importance', update importance for all vendors
      if (target === "importance") {
        // Create deep copy of existing data
        let scorecardDataCopy = JSON.parse(JSON.stringify(state.scoreCardData));

        // Create a new array with all data updated immutably
        scorecardDataCopy = scorecardDataCopy.map((data) => ({
          ...data, // Shallow copy of data object
          items: data.items.map((item) => {
            // Check if this is the item to update
            if (
              item.requirement === questionText &&
              data.category === questionCategory
            ) {
              // Return a new object with the updated importance
              return { ...item, importance: value };
            }
            // For all other items, return them unchanged
            return item;
          }),
        }));

        state.scoreCardData = scorecardDataCopy;
      } else {
        // Create deep copy of existing data
        let scorecardDataCopy = JSON.parse(JSON.stringify(state.scoreCardData));

        // Find the scoreCardData entry that matches the currentVendor and category
        const scoreCardCategory = scorecardDataCopy.find(
          (data) => data.vendor === currentVendor && data.category === category
        );

        if (scoreCardCategory) {
          // Find the item with the matching requirement text
          const item = scoreCardCategory.items.find(
            (i) => i.requirement === questionText
          );

          if (item) {
            // Update the existing item
            if (target === "score") {
              item.score = value;
            } else if (target === "comment") {
              console.log("UPDATING COMMENT");
              item.comment = value;
            }
          } else {
            // Add new item if not found
            scoreCardCategory.items.push({
              requirement: questionText,
              score: target === "score" ? value : 0,
              importance: target === "importance" ? value : 0,
              comment: target === "comment" ? value : "",
            });
          }
        } else {
          // If no such vendor/category combination exists, create a new entry in scoreCardData
          scorecardDataCopy.push({
            vendor: currentVendor,
            category: questionCategory,
            items: [
              {
                requirement: questionText,
                score: target === "score" ? value : 0,
                importance: target === "importance" ? value : 0,
                comment: target === "comment" ? value : "",
              },
            ],
          });
        }

        // Update the state with the modified deep copy
        state.scoreCardData = scorecardDataCopy;
      }
    },
  },
  extraReducers: {
    // ----------
    // getSimplifiedRequirementsList
    // ----------
    [getSimplifiedRequirementsList.pending]: (state) => {
      state.availableRequirementsStatus = "loading";
    },
    [getSimplifiedRequirementsList.fulfilled]: (state, action) => {
      state.availableRequirementsStatus = "succeeded";
      state.availableRequirements = action.payload.requirements;
    },
    [getSimplifiedRequirementsList.rejected]: (state, action) => {
      state.availableRequirementsStatus = "failed";
      state.error = action.payload;
    },

    // ----------
    // populateRequirementDetails
    // ----------
    [populateRequirementDetails.pending]: (state) => {
      state.status = "loading";
    },
    [populateRequirementDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.requirementDetailView = action.payload;
    },
    [populateRequirementDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // submitScorecardScores
    // ----------
    [submitScorecardScores.pending]: (state) => {
      state.status = "loading";
    },
    [submitScorecardScores.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.requirementDetailView = action.payload;
    },
    [submitScorecardScores.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // selectWinner
    // ----------
    [selectWinner.pending]: (state) => {
      state.status = "loading";
    },
    [selectWinner.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // state.scorecard = action.payload;
    },
    [selectWinner.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // requestAiScorecard
    // ----------
    [requestAiScorecard.pending]: (state) => {
      state.aiScorecardStatus = "loading";
    },
    [requestAiScorecard.fulfilled]: (state, action) => {
      state.aiScorecardStatus = "succeeded";
      state.aiScorecardSuggestions = action.payload;

      // Response contains the array of strings (suggestions)
      const responseArray = action.payload;

      // Deep copy the existing state
      let availableRequirementsCopy = JSON.parse(
        JSON.stringify(state.availableRequirements)
      );
      let selectedScorecardRequirementsCopy = JSON.parse(
        JSON.stringify(state.selectedScorecardRequirements)
      );

      // Loop through availableRequirementsCopy and filter the matched items
      availableRequirementsCopy = availableRequirementsCopy.filter(
        (requirement) => {
          if (responseArray.includes(requirement.name)) {
            // Move the matched requirement to selectedScorecardRequirementsCopy
            selectedScorecardRequirementsCopy.push(requirement);
            return false; // Remove it from availableRequirementsCopy
          }
          return true; // Keep it in availableRequirementsCopy
        }
      );

      // Remove duplicates based on the "name" field in selectedScorecardRequirementsCopy
      const uniqueSelectedScorecardRequirements =
        selectedScorecardRequirementsCopy.reduce((acc, current) => {
          if (!acc.find((item) => item.name === current.name)) {
            acc.push(current);
          }
          return acc;
        }, []);

      // Set the updated arrays back to state
      state.availableRequirements = availableRequirementsCopy;
      state.selectedScorecardRequirements = uniqueSelectedScorecardRequirements;

      toast.success("Scorecard Generated");
    },
    [requestAiScorecard.rejected]: (state, action) => {
      state.aiScorecardStatus = "failed";
      state.error = action.payload?.message || action.error.message;
      toast.error("Error. Please try again.");
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeVendor,
  initForm,
  updateScore,
  addRequirementToScorecard,
  addCustomRequirementToScorecard,
  removeRequirementFromScorecard,
  setCategories,
  setScoreCardData,
  setSelectedScorecardRequirements,
  setInitVendors,
  setScorecardTemplate,
} = scoreVendorsSlice.actions;

export default scoreVendorsSlice.reducer;
