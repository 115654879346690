import React from "react";
import { useSelector } from "react-redux";

export default function WinnerDisplay({ winner }) {
  const vendors = useSelector((state) => state.vendors.vendors);

  // Find the vendor whose name matches the winner's name
  const selectedWinner = vendors.find(
    (vendor) => vendor.VendorName === winner?.winner
  );
  const selectedWinnerImg = selectedWinner ? selectedWinner.Logo : null;

  return (
    <div className="flex flex-col items-center w-full border border-warning-400 rounded-lg shadow-lg p-6 mb-6 bg-gradient-to-b from-warning-300/50 via-warning-500/50 to-warning-700/50 text-white">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        Your Shortlist Match Winner
      </h1>

      <div className="mb-6 max-w-3xl">
        {selectedWinnerImg ? (
          <img
            src={selectedWinnerImg}
            alt={`${winner?.winner} logo`}
            className="bg-white w-full flex items-center justify-center p-6 py-12"
          />
        ) : (
          <div className="bg-white w-full flex items-center justify-center">
            <span className="text-gray-600">No Image</span>
          </div>
        )}
      </div>

      <span className="text-xl font-semibold text-gray-900">Winner:</span>

      <h2 className="my-2 w-full text-center max-w-md text-lg col-span-2 bg-white p-4 rounded-md border-2 border-warning-200">
        <span className="text-4xl text-gray-900 font-bold">
          {winner?.winner}
        </span>
      </h2>

      <div className="text-gray-900 grid grid-cols-2 w-full max-w-md gap-2 mt-2">
        <h2 className="flex flex-col text-lg col-span-2 bg-white p-2 rounded-md border-2 border-warning-200">
          <span className="font-semibold text-gray-800">Reason:</span>
          <span className="text-sm">{winner?.reason}</span>
        </h2>
        <h2 className="text-lg text-center bg-white p-2 border-2 border-warning-200 rounded-md flex items-center justify-between flex flex-col sm:flex-row">
          <span className="font-semibold">License Cost:</span>{" "}
          <span>{winner?.licenseCost}</span>
        </h2>
        <h2 className="text-lg text-center bg-white p-2 border-2 border-warning-200 rounded-md flex items-center justify-between flex flex-col sm:flex-row">
          <span className="font-semibold">Setup Cost:</span>{" "}
          <span>{winner?.setupCost}</span>
        </h2>
      </div>
    </div>
  );
}
